/* global $, Menu */
'use strict';

( function( factory ) {
	if ( typeof define === "function" && define.amd ) {

		// AMD. Register as an anonymous module.
		define( [ "../widgets/datepicker" ], factory );
	} else {
		// Browser globals
		factory( jQuery.datepicker );
	}
}( function( datepicker ) {

datepicker.regional.nl = {
	closeText: "Sluiten",
	prevText: "←",
	nextText: "→",
	currentText: "Vandaag",
	monthNames: [ "januari", "februari", "maart", "april", "mei", "juni",
	"juli", "augustus", "september", "oktober", "november", "december" ],
	monthNamesShort: [ "jan", "feb", "mrt", "apr", "mei", "jun",
	"jul", "aug", "sep", "okt", "nov", "dec" ],
	dayNames: [ "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag" ],
	dayNamesShort: [ "zon", "maa", "din", "woe", "don", "vri", "zat" ],
	dayNamesMin: [ "zo", "ma", "di", "wo", "do", "vr", "za" ],
	weekHeader: "Wk",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "" };
datepicker.setDefaults( datepicker.regional.nl );

return datepicker.regional.nl;
} ) );

$(document).ready(function($) {
    if ($("#repairform").length == 1) {
        $('input[type=checkbox]').click(function() {
            var checked = 0;
            $("#repairform input[type=checkbox]").each(function() {
                if ($(this).prop('checked') == true) {
                    checked++;
                }
            });
            if (checked == 0) {
                $('#repairform button').prop("disabled", true);
            } else {
                $('#repairform button').prop("disabled", false);
            }
        });
        $("#repairOrderButton").click(function() {
            $(this).parent('form').submit();
        });
    }
    $(".removeDamageButton, .removeImageButton").click(function(e){
        e.preventDefault();
        $(this).parents('form').submit();
    });

    $('#state-button').click(function(){
        $(this).hide();
        $("#state-form").show();
    });

    $("#state-form select").change(function(){
        $(this).parents('form').submit();
    });

    $(".datepicker").each(function(e){
        var name = $(this).attr('id');
        console.log(name);
        $(this).datepicker( {
            dateFormat:"dd-mm-yy",
            altFormat: "yy-mm-dd",
            altField: '#real_'+name
        });

    });

    $('#registerform .transport').change(function(){
        var val= $(this).val();
        if (val != 'none'){
            $("#transport_location").show();
        } else {
            $("#transport_location").hide();
        }
    });

    $('#registerform .transport_location').change(function(){
        var val= $(this).val();
        if (val != 'central'){
            $("#specific_location").show();
        } else {
            $("#specific_location").hide();
        }
    });
    $('#damages .addNewDamage').click(function() {
        var $damages = $('#damages');
        var current_count = parseInt($damages.data('rowcount'));
        var next_count = current_count + 1;

        $damages.data('rowcount', next_count);

        $damages.find('.row.damage:first')              // Get the first damage row
            .clone()                                    // Clone it
            .insertAfter('#damages .row.damage:last')   // Place it below the last one
            .find('input')                              // Get all inputs in it
            .val('');                                   // Empty them

        var $lastrow = $damages.find('.row.damage:last');  // Newly added row
        $lastrow.find('input, select')
            .each(function(i, input){
                var $input = $(input); // Wrap element in jQuery object
                var name = $input.attr('name');
                var new_name = name.replace(0, next_count);

                $input.attr('name', new_name);
                $input.attr('id', new_name);

                // Replace target of labels belonging to this input
                $input
                    .siblings()
                    .find('label')
                    .attr('for', new_name);
            });

        $lastrow.find('.remove')
            .show()
            .off('click')
            .click(function(e){
                e.preventDefault();
                $(this).parent().parent().remove();
            });
    });

        /* damages inline edit form */

    $('.inlineEditForm .glyphicons').click(function(){
        var element = $(this).parent().find('.value');
        var value = element.text();
        console.log(value);
        $(element).html('');
        $('<input></input>')
            .attr({
                'type': 'text',
                'name': 'value',
                'id': 'changement',
                'size': '30',
                'value': value
            })
            .appendTo(element).focus();
        $('<span></span>')
            .attr({
                'class': 'glyphicons glyphicons-ok-circle'
            })
            .appendTo(element)
            .click(function(){
                var changeInput = $(this).parent().find('input');
                // SAVE THE VALUE!
                var saving = true;
                var value = changeInput.val();

                // if data is saved, reset form
                if (saving == true){
                    // drop the input field
                    changeInput.remove();
                    $(this).parent().parent().find('.glyphicons').show();
                    // remove save button
                    $(this).parent().text(value);
                } else {
                    // what if wrong input was given?
                }
            });
        $(this).hide();
    });

    $.fn.dataTable.moment( 'DD-MM-YYYY' );

    $('#vehicles-table, #dossiers-table ').dataTable({
        "language": {
            "url": "//cdn.datatables.net/plug-ins/1.10.12/i18n/Dutch.json"
        },
        "order": [ 0, 'desc' ]
    });


    $('#license_plate').on('blur',function(){
        var license = $('#license_plate').val();
        getVehicleData(license);
    });
    $('#loadVehicle').click(function(){
        var license = $('#license_plate').val();
        getVehicleData(license);
    });
});

function getVehicleData(license)
{
    $.ajax({
        url: "/rdw/kenteken/" + license,
        type: "GET",
    }).done(function(data) {
        $('#manufacturer').val(data.manufacturer);
        $('#manufacturer').addClass('form--autofill')

        $('#edition').val(data.edition);
        $('#edition').addClass('form--autofill')

        $('#color').val(data.color);
        $('#color').addClass('form--autofill')

        $('#body').val(data.body);
        $('#body').addClass('form--autofill')

        $('#fuel_type').val(data.fuel_type);
        $('#fuel_type').addClass('form--autofill')

        $('#seat_count').val(data.seats);
        $('#seat_count').addClass('form--autofill')

        $('#build_year').val(data.build_year);
        $('#build_year').addClass('form--autofill')

        // eerste tenaamstelling
        var first_name_date = Date.parse(data.first_name_date);
        $('#first_name_date').val(moment(first_name_date).format('DD-MM-Y'));
        $('#real_first_name_date').val(moment(first_name_date).format('Y-MM-DD'));
        $('#first_name_date').addClass('form--autofill');

        // APK
        var inspection = Date.parse(data.inspection);
        $('#inspection').val(moment(inspection).format('DD-MM-Y'));
        $('#real_inspection').val(moment(inspection).format('Y-MM-DD'));
        $('#inspection').addClass('form--autofill');

        // catalogusprijs
        $('#fiscal_value').val(data.price);
        $('#fiscal_value').addClass('form--autofill');


        console.log(data);

    });
}


